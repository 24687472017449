import { gsap } from "gsap"

import { Flip } from "gsap/Flip"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"

gsap.registerPlugin(Flip)
gsap.registerPlugin(MotionPathPlugin)

const heroFlipDuration = 2 // Seconds
const logoFlightDuration = 4 // Seconds
const gsapEase = "power1.inOut"

const heroElement = document.getElementById("hero")
const logoElement = document.getElementById("logo")
const pathElement = document.getElementById("path")

// Note: CSS handles some of the step actions instead of JavaScript!
// See main.scss for step descriptions

const animateStepOne = () => {
    heroElement.classList.add("hero--step-one")

    // Animate logo over a path
    gsap.to(logoElement, {
        motionPath: {
            path: pathElement,
            align: pathElement,
            alignOrigin: [0.5, 0.5],
            autoRotate: 180, // Needs to be 180 because the SVG is horizontally flipped by CSS
        },
        duration: logoFlightDuration,
        onComplete: animateStepTwo,
    })
}

const animateStepTwo = () => {
    const logoState = Flip.getState(logoElement)
    heroElement.classList.replace("hero--step-one", "hero--step-two")

    // Move logo upwards
    Flip.from(logoState, {
        onEnter: gsap.set(logoElement, {clearProps: "transform"}),
        duration: heroFlipDuration,
        ease: gsapEase,
        onComplete: animateStepThree,
    })
}

const animateStepThree = () => {
    heroElement.classList.replace("hero--step-two", "hero--step-three")

    setTimeout(function () {
        animateStepFour()
    }, heroFlipDuration * 1000)
}

const animateStepFour = () => {
    heroElement.classList.replace("hero--step-three", "hero--step-four")

    setTimeout(function () {
        animateStepFive()
    }, heroFlipDuration * 1000)
}

const animateStepFive = () => {
    heroElement.classList.replace("hero--step-four", "hero--step-five")
}

export const runHeroAnimations = () => {
    animateStepOne()
}
