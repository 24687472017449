import { runHeroAnimations } from "./modules/HeroAnimate"

// Update copyright year
// This is a fallback for when the website misses a yearly deployment
document.getElementById("copyright-current-year").innerHTML = `${new Date().getFullYear()}`

// Hero animation
const hasPrefersReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches
const hasSlowUpdate = window.matchMedia("(update: slow)").matches
const isPrintMode = window.matchMedia("print").matches

const shouldAnimate = !(hasPrefersReducedMotion || hasSlowUpdate || isPrintMode)
if (shouldAnimate) {
    runHeroAnimations()
} else {
    document.querySelector("body").classList.add("no-animations")
}
